import { ref } from "@vue/composition-api";
import store from "@/store";
import toast from "@/utils/toast";
import schoolStoreModule from "../schoolStoreModule";

export default function useSchoolModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = "school";

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, schoolStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const onSubmit = async (bvModalEvt) => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      name: itemLocal.value.name,
      address: itemLocal.value.address,
      tingeeId: itemLocal.value.tingee ? itemLocal.value.tingee.id : null,
      templateId: itemLocal.value.template ? itemLocal.value.template.id : null,
      eInvoiceConfigId: itemLocal.value.eInvoiceConfig
        ? itemLocal.value.eInvoiceConfig.id
        : null,
    };

    isSubmitting.value = true;

    if (itemLocal.value.id && itemLocal.value.id > 0) {
      store
        .dispatch("school/updateSchool", { data, id: itemLocal.value.id })
        .then((response) => {
          emit("on-item-updated", response.data);
        })
        .then(() => {
          refModal.value.toggle("#toggle-btn");
        })
        .then(() => {
          emit("refetch-data");
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch((error) => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch("school/createSchool", data)
        .then((response) => {
          emit("on-item-created", response.data);
        })
        .then(() => {
          refModal.value.toggle("#toggle-btn");
        })
        .then(() => {
          emit("refetch-data");
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch((error) => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    onSubmit,
  };
}
