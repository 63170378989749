<template>
  <div>
    <b-form-group :state="state">
      <template v-if="label !== null" v-slot:label>
        {{ label }}
        <span v-if="required" class="text-danger"> (*) </span>
      </template>
      <v-select
        id="template"
        v-model="config"
        label="sellerLegalName"
        :options="eInvoiceConfigs"
        placeholder="Cấu hình hoá đơn điện tử"
        @input="configChanged"
        @open="onOpen"
      >
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          Không có dữ liệu
        </template></v-select
      >
      <small class="text-danger">{{ error }}</small>
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Cấu hình hoá đơn điện tử",
    },
    enableAdd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      config: this.defaultValue,
      eInvoiceConfigs: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    configChanged() {
      this.$emit("input", this.config);
    },
    fetchData() {
      useJwt
        .getEInvoiceConfigData()
        .then((res) => {
          this.eInvoiceConfigs = res.data.items
        })
    },
    onOpen() {
      if (this.eInvoiceConfigs.length === 0) {
        this.fetchData();
      }
    },
    onItemCreated(newItem) {
      this.config = newItem;
      this.configChanged();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/vue-select.scss";
</style>
